import { publicPath } from './fixWebpackPublicPath';
import { loadScript } from '@sas-dvr/ltjs-commons/util/ltjsLoaderUtil';
import * as WebElements from './elements';
import '@sas/nova-commons/embedded.css';
import './loadCss';
import { displayImagesForMobile } from './elements/dynamicImports/isMobile';
const customElementCSS = `
sas-report, sas-report-object, sas-report-page {
  display: block;
}

sas-report {
  min-width: 50px;
  min-height: 50px;
}
`;
// Generate css for custom elements
{
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(customElementCSS));
    document.head.appendChild(style);
}
if (displayImagesForMobile()) {
    import('./elements/dynamicImports/mobileImportsImpl').then(({ renderers }) => {
        window.vaReportComponents = Object.assign({}, renderers);
        const event = new CustomEvent('vaReportComponents.loaded');
        dispatchEvent(event);
    });
}
else {
    //
    // Load LTJS
    // Set the ltjslibpath relative to this loader script.
    // sas.ltjs is initialized as part of the ltjsLoaderUtil
    //
    window.sas.ltjs.resourceUrl = publicPath + 'assets/';
    loadScript([]);
    import('./elements/dynamicImports/dynamicImportsImpl').then(({ SASReport, SASReportObject, SASReportPage, registerDataDrivenContent }) => {
        //TODO: Type validation?
        window.vaReportComponents = Object.assign(Object.assign({ 
            //TODO: remove the undocumented react exports?
            // We could shim etc. the other exports and remove the need for vaReportComponents.loaded (but maybe keep it around)
            SASReport,
            SASReportObject,
            SASReportPage }, WebElements), { registerDataDrivenContent });
        const event = new CustomEvent('vaReportComponents.loaded');
        dispatchEvent(event);
    });
}
