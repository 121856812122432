import { publicPath } from './fixWebpackPublicPath';
// TODO: don't create if someone has already added a style element?
let resolveLoaded;
let rejectLoaded;
let hasLoaded = 'loading';
const promise = new Promise((resolve, reject) => {
    resolveLoaded = resolve;
    rejectLoaded = reject;
});
const vaCss = document.createElement('link');
vaCss.rel = 'stylesheet';
vaCss.href = `${publicPath}style.css`;
document.head.appendChild(vaCss);
vaCss.onload = function () {
    hasLoaded = 'loaded';
    resolveLoaded();
};
vaCss.onerror = function () {
    console.error('va-sdk:ERROR: css failed to load');
    hasLoaded = 'error';
    rejectLoaded();
};
export function whenCssLoaded() {
    if (hasLoaded === 'loaded') {
        return Promise.resolve();
    }
    else if (hasLoaded === 'error') {
        return Promise.reject();
    }
    return promise;
}
